





















































































































import Vue from 'vue';
import mixin from './vue-file-agent-mixin';

export default Vue.extend({
  mixins: [mixin],
});
